/*----------------------------------------*/
/*  20 - Components - Modal
/*----------------------------------------*/
/* ---Modal--- */
.modal-wrapper {
	display: block !important;
	&.modal {
		visibility: hidden;
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		@extend %basetransition;
		z-index: -9;
	}
	&.show {
		background-color: rgba(0, 0, 0, 0.7);
		visibility: visible;
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		z-index: 1050;
	}
	.modal-dialog {
		width: 1170px;
		max-width: 95%;
		@include breakpoint (max-x_small) {
			max-width: 100%;
			left: 0;
		}
		.modal-content {
			padding: 30px 30px 25px;
			overflow-y: auto;
			@include breakpoint (normal) {
				padding: 30px 20px 25px;
			}
			.modal-body {
				padding: 0;
				.close {
					border: 1px solid $bg-nobel_color;
					color: $bg-nobel_color;
					border-radius: 3px;
					font-size: 28px;
					font-weight: 400;
					opacity: 1;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
					position: absolute;
					right: 15px;
					top: 0;
					width: 30px;
					height: 30px;
					line-height: 26px;
					z-index: 99;
					@extend %basetransition;
				}
				.modal-inner-area {
					padding: 0 0 30px !important;
					.sp-img_slider-2 {
						border: 1px solid $border-color;
					}
					.sp-img_slider-nav {
						padding: 0 30px;
						margin-top: 30px;
						.slick-list {
							.single-slide {
								border: 1px solid $border-color;
								margin-left: 15px;
								@extend %basetransition;
								img {
									width: 100%;
								}
							}
						}
					}
					.sp-content {
						@include breakpoint (max-medium) {
							padding-top: 30px;
						}
						.sp-heading {
							> h5 {
								margin-bottom: 0;
								padding-bottom: 20px;
								@include breakpoint (max-normal) {
									font-size: 18px;
								}
							}
						}
						.price-box {
							padding-bottom: 15px;
							@include new-price;
							.new-price {
								font-size: 24px;
							}
							@include old-price;
						}
						.features {
							padding: 10px 0 15px;
						}
						.quantity-area {
							border-bottom: 1px solid $border-color;
							padding-bottom: 30px;
							display: flex;
							align-items: center;
							@include breakpoint (max-medium) {
								display: block;
							}
							.quantity {
								display: flex;
								align-items: center;
								label {
									margin-bottom: 0;
									padding-right: 10px;
								}
							}
							.quantity-btn {
								padding-left: 15px;
								@include breakpoint (max-medium) {
									padding-left: 0;
									padding-top: 30px;
								}
								> ul {
									> li {
										display: inline-block;
										margin-left: 5px;
										&:first-child {
											margin-left: 0;
										}
										> a {
											width: 140px;
											height: 40px;
											line-height: 40px;
											display: block;
											text-align: center;
											border-radius: 22px;
											font-weight: 600;
											> i {
												font-size: 18px;
											}
										}
									}
								}
							}
						}
						.short-desc {
							border-bottom: 1px solid $border-color;
							padding: 25px 0 25px 0;
							> p {
								margin-bottom: 0;
							}
						}
						.munoz-social_link {
							padding-top: 25px;
							.social-title {
								padding-bottom: 25px;
								> h3 {
									margin-bottom: 0;
									text-transform: uppercase;
									font-weight: $body-font_weight;
									font-size: 18px;
								}
							}
							ul {
								> li {
									> a {
										border: 1px solid $border-color;
									}
									&.facebook {
										> a {
											&:hover {
												background-color: $facebook-color;
											}
										}
									}
									&.twitter {
										> a {
											&:hover {
												background-color: $twitter-color;
											}
										}
									}
									&.youtube {
										> a {
											&:hover {
												background-color: $youtube-color;
											}
										}
									}
									&.google-plus {
										> a {
											&:hover {
												background-color: $google-plus-color;
											}
										}
									}
									&.instagram {
										> a {
											&:hover {
												background-color: $instagram-color;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
