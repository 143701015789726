/*----------------------------------------*/
/*  15 - Components - Product
/*----------------------------------------*/
.munoz-product_area {
	padding-top: 55px;
}

/* ---Munoz's Product Slider--- */
.munoz-product_slider,
.munoz-product_slider-2,
.munoz-product_slider-3,
.munoz-product-tab_slider,
.munoz-content_wrapper {
	.slide-item {
		.single-product {
			padding: 20px;
			@extend %basetransition;
			.product-img {
				position: relative;
				text-align: center;
				> a {
					display: block;
					.secondary-img {
						position: absolute;
						left: 0;
						top: 0;
						opacity: 0;
						width: 100%;
						@extend %basetransition;
					}
				}
				&:hover {
					.secondary-img {
						opacity: 1;
					}
				}
				.add-actions {
					background-color: $bg-snow_color;
					box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
					opacity: 0;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					@extend %basetransition;
					border-radius: 25px;
					padding: 10px 0;
					z-index: 9;
					> ul {
						> li {
							display: block;
							> a {
								display: block;
								width: 0;
								height: 35px;
								line-height: 35px;
								text-align: center;
								border-radius: 25px;
								&:hover {
									background-color: $primary-color;
									color: $bg-white_color !important;
								}
								> i {
									font-size: 20px;
								}
							}
						}
					}
				}
			}
			.product-content {
				padding-top: 30px;
				.product-desc_info {
					.price-box {
						@include new-price;
						@include old-price;
					}
					.product-category{
						font-weight: $body-font_weight;
						position: relative;
						margin-bottom: 10px;
						padding-left: 30px;
						a {
							color: $dim-gray_color;
							font-size: 14px;
							display: block;
							&:before{
								content: "";
								background-color: #949494;
								height: 2px;
								width: 20px;
								position: absolute;
								left: 0;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
					h3{
						&.product-name{
							font-weight: $heading-font_weight-2;
							margin-bottom: 0;
							font-size: 18px;
							padding-bottom: 10px;
							@include breakpoint (medium){
								font-size: 16px;
							}
						}
					}
					.rating-box {
						padding-top: 10px;
						> ul {
							> li {
								display: inline-block;
								> i {
									font-size: 18px;
								}
							}
						}
					}
				}
			}
			&:hover {
				.product-img {
					.add-actions {
						opacity: 1;
						> ul{
							> li{
								> a{
									width: 35px;
								}
							}
						}
					}
				}
			}
		}
	}
	/* ---Munoz's Spacing Between Row---*/
	.slick-list{
		margin: -15px;
	}
	.slick-slide {
		div {
			.slide-item {
				margin: 15px 0;
			}
		}
	}
}

/* ---Munoz's Product Area Two--- */
.munoz-product_area-2{
	.munoz-section_area{
		> p{
			&.short-desc{
				max-width: 50%;
				@include breakpoint (max-medium){
					max-width: 65%;
				}
				@include breakpoint (max-small){
					max-width: 90%;
				}
				@include breakpoint (xx-small){
					max-width: 100%;
				}
			}
		}
	}
	.munoz-product_slider-2 {
		.slide-item {
			margin: 15px 0;
		}
		.slick-list{
			margin: -15px;
		}
	}
}

/* ---Munoz's Product Area Three--- */
.munoz-product_area-3 {
	background-image: url("../images/special-product/1.jpg");
	padding: 55px 0 60px;
	margin-top: 60px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	@include breakpoint (max-medium){
		background-position: left;
	}
	.munoz-product_slider-3 {
		.slide-item {
			.single_product {
				.product-content {
					width: 100%;
					.product-desc_info {
						> h2 {
							&.product-name{
								margin-bottom: 0;
								padding-bottom: 20px;
								@include breakpoint (max-x_small){
									font-size: 25px;
								}
							}
						}
						> p {
							&.product-desc{
								margin-bottom: 0;
								padding-bottom: 20px;
								max-width: 70%;
								@include breakpoint (xx-small){
									max-width: 100%;
								}
							}
						}
						.munoz-btn-ps_left {
							padding-top: 25px;
						}
					}
				}
			}
		}
	}
}

/* ---Munoz's countdown--- */
.munoz-countdown {
	border-radius: 50px;
	.count {
		display: inline-block;
		position: relative;
		width: 20%;
		padding: 5px 0;
		@include breakpoint (max-medium){
			width: 10%;
		}
		@include breakpoint (max-small){
			width: 20%;
		}
		@include breakpoint (xx-small){
			width: 25%;
		}
		span {
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			&.count-amount {
				border-top: 2px solid $border-color;
				border-bottom: 2px solid $border-color;
				display: block;
				font-weight: 600;
				font-size: 18px;
			}
			&.count-period {
				display: block;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
	}
}
