/*----------------------------------------*/
/*  17 - Components - Form
/*----------------------------------------*/
/* ---Munoz's Newsletter Form--- */
.newsletter-form_wrap {
	.subscribe-form {
		position: relative;
		.newsletter-input {
			border: 1px solid $border-color;
			border-radius: 50px;
			font-size: 14px;
			height: 50px;
			width: 100%;
			padding: 0 127px 0 20px;
		}
		.newsletter-btn {
			text-transform: uppercase;
			position: absolute;
			font-size: 21px;
			top: 0;
			right: -2px;
			width: 120px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			border-radius: 50px;
			font-size: 14px;
			@extend %basetransition;
		}
	}
}
