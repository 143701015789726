/*----------------------------------------*/
/*  22 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
	background-image: url("../images/breadcrumb/1.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 380px;
	position: relative;
	@include breakpoint (max-large){
		min-height: 200px;
	}
	@include breakpoint (max-small){
		min-height: 140px;
	}
	.breadcrumb-content {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		@include breakpoint (max-x_small){
			width: 100%;
		}
		> h2 {
			font-weight: $heading-font_weight;
			text-transform: uppercase;
			font-size: 32px;
			margin-bottom: 0;
			padding-bottom: 20px;
			@include breakpoint (max-x_small){}
			font-size: 25px;
		}
		ul {
			> li {
				display: inline-block;
				padding-left: 20px;
				position: relative;
				&:first-child {
					padding-left: 0;
				}
				&:before {
					content: "\f054";
					font-family: 'Font Awesome 5 Free';
					font-weight: 600;
					position: absolute;
					right: -15px;
					top: 2px;
					font-size: 10px;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
