/*----------------------------------------*/
/*  11 - Components - Slider
/*----------------------------------------*/
/* ---Munoz's Slider Area--- */
.munoz-slider_area {
	.main-slider {
		overflow: hidden;
		.slider-content {
			top: 50%;
			left: auto;
			transform: translateY(-50%);
			position: absolute;
			z-index: 8;
			max-width: 30%;
			@include breakpoint (max-large){
				max-width: 38%;
			}
			@include breakpoint (max-small){
				max-width: 50%;
			}
			@include breakpoint (xx-small){
				max-width: 100%;
			}
			> h3 {
				font-weight: $heading-font_weight-2;
				font-size: 52px;
				margin-bottom: 0;
				padding-bottom: 20px;
				@include breakpoint (max-medium){
					font-size: 35px;
				}
				@include breakpoint (max-x_small){
					font-size: 30px;
					padding-bottom: 15px;
				}
			}
			> h4 {
				font-size: 52px;
				margin-bottom: 0;
				padding-bottom: 35px;
				font-weight: $heading-font_weight-2;
				@include breakpoint (max-medium){
					font-size: 35px;
				}
				@include breakpoint (max-x_small){
					font-size: 30px;
					padding-bottom: 25px;
				}
			}
			> p{
				margin-bottom: 0;
				padding-bottom: 35px;
				max-width: 75%;
				@include breakpoint (max-large){
					max-width: 80%;
				}
				@include breakpoint (max-normal){
					max-width: 100%;
				}
				@include breakpoint (max-x_small){
					padding-bottom: 25px;
				}
				@include breakpoint (xx-small){
					max-width: 90%;
				}
			}
		}
		.slick-arrow{
			left: 20px;
			&.slick-next {
				left: auto;
				right: 20px;
			}
		}
	}
}

/* ---Munoz's Slider Area Two--- */
.munoz-slider_area-2{
	.main-slider{
		.slider-content{
			> h3,
			> h4,
			> P{
				color: $bg-white_color;
			}
			.munoz-btn-ps_left{
				> a{
					&.munoz-btn-2{
						border: 2px solid $bg-white_color;
						color: $bg-white_color;
					}
				}
			}
		}
	}
}

/* --Slider Background Image-- */
// Home One
.bg-1,
.bg-2 {
	background-image: url('../images/slider/1.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 800px;
	@include breakpoint (max-normal) {
		min-height: 480px;
	}
	@include breakpoint (max-x_small) {
		min-height: 380px;
	}
}
.bg-2 {
	background-image: url('../images/slider/2.jpg');
}
// Home Two
.bg-3,
.bg-4 {
	background-image: url('../images/slider/3.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 800px;
	@include breakpoint (max-normal) {
		min-height: 480px;
	}
	@include breakpoint (max-x_small) {
		min-height: 380px;
	}
}
.bg-4 {
	background-image: url('../images/slider/4.jpg');
}

/* ---Munoz's Slider Progress Bar--- */
.slider-progress {
	-webkit-animation: initial;
	animation: initial;
	background: rgba(0, 0, 0, .3);
	height: 5px;
	left: 0;
	opacity: 0.8;
	position: absolute;
	top: 0;
	z-index: 4;
}

.slick-current .slider-progress {
	-webkit-animation: timebar 8s ease-in-out 0s 1 backwards;
	animation: timebar 8s ease-in-out 0s 1 backwards;
}

// Hiraola's Keyframes
@-webkit-keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
