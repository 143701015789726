/*----------------------------------------*/
/*  40 - Pages - FAQ
/*----------------------------------------*/
.frequently-area {
	padding: 55px 0 0;
	.frequently-content {
		.frequently-desc {
			margin-bottom: 25px;
			h3 {
				font-size: 15px;
				font-weight: 600;
				text-transform: capitalize;
				line-height: 24px;
			}
			p {
				margin: 0;
				font-size: 14px;
			}
		}
	}
	.frequently-accordion {
		.actives {
			border: 1px solid $primary-color;
			> .card-header {
				background-color: $primary-color;
				> h5 {
					> a {
						font-weight: 600;
						color: $bg-white_color;
						&:before {
							color: $bg-white_color;
						}
						&:after {
							color: $bg-white_color;
						}
						&:hover {
							color: $bg-white_color !important;
						}
					}
				}
			}
		}
		[class*="card"] {
			&:last-child {
				margin-bottom: 0;
			}
		}
		.card {
			border-radius: 0;
			margin-bottom: 15px;
		}
		.card-header {
			padding: 0;
			margin-bottom: 0;
			background-color: $white-smoke_color;
			border: 0;
			a {
				display: block;
				padding: 15px 10px 15px 25px;
				cursor: pointer;
				font-size: 14px;
				font-weight: 400;
				text-transform: capitalize;
				@include breakpoint (max-x_small){
					line-height: 20px;
				}
				&:hover {
					color: $primary-color !important;
				}
				&.collapsed {
					&:before {
						content: "\f067";
						font-family: 'Font Awesome 5 Free';
						font-weight: 600;
						position: absolute;
						right: 15px;
						top: 15px;
						color: $bg-silver_color;
						@include breakpoint (xx-small) {
							display: none;
						}
					}
				}
				&:after {
					content: "\f068";
					font-family: 'Font Awesome 5 Free';
					font-weight: 600;
					position: absolute;
					right: 15px;
					top: 15px;
					color: $bg-silver_color;
					@include breakpoint (xx-small) {
						display: none;
					}
				}
			}
		}
	}
}
