/*----------------------------------------*/
/*  19 - Components - Product Tab
/*----------------------------------------*/
/* ---Munoz's Product Tab Area--- */
.munoz-product-tab_area {
	padding: 60px 0 0;
	.tab-section_area {
		display: flex;
		justify-content: space-between;
		padding-bottom: 25px;
		@include breakpoint (max-x_small) {
			display: block;
			text-align: center;
		}
		.product-tab_title {
			> h3 {
				margin-bottom: 0;
				font-size: 24px;
				font-weight: $heading-font_weight;
				text-transform: uppercase;
				position: relative;
				@include breakpoint (max-x_small){
					padding-bottom: 20px;
				}
			}
		}
		.product-tab {
			display: flex;
			justify-content: center;
			> ul {
				&.product-menu {
					> li {
						> a {
							padding: 0 20px;
							display: block;
							text-transform: uppercase;
							@include breakpoint (max-medium) {
								padding-left: 0;
							}
							> span {
								position: relative;
								font-weight: $body-font_weight-2;
								&:before {
									content: "";
									border-left-width: 2px;
									border-left-style: solid;
									border-left-color: inherit;
									position: absolute;
									top: 50%;
									left: -20px;
									transform: translateY(-50%);
									height: 13px;
									opacity: 0;
									visibility: hidden;
									@extend %basetransition;
									@include breakpoint (large){
										display: none;
									}
								}
								&:after {
									content: "";
									border-left-width: 2px;
									border-left-style: solid;
									border-left-color: inherit;
									position: absolute;
									top: 50%;
									right: -20px;
									transform: translateY(-50%);
									height: 13px;
									opacity: 0;
									visibility: hidden;
									@extend %basetransition;
									@include breakpoint (large){
										display: none;
									}
								}
							}
						}
						&:last-child {
							> a {
								padding-right: 0;
							}
						}
						&:hover {
							> a {
								> span {
									&:before {
										visibility: visible;
										opacity: 1;
										left: -10px;
									}
									&:after {
										visibility: visible;
										opacity: 1;
										right: -10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* ---Munoz's Product Tab Area Three--- */
.sp-product-tab_area {
	background-color: $bg-white_color;
	padding: 0 0 60px;
	.short-desc {
		margin: 0 auto;
		text-align: center;
		> p {
			margin-bottom: 0;
			padding-bottom: 15px;
		}
	}
}
