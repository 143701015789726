/*----------------------------------------*/
/*  30 - Pages - Single Product
/*----------------------------------------*/

/* ---Single Product Page--- */
.sp-area {
	padding: 35px 0 0;
	.sp-nav {
		background-color: $bg-white_color;
		padding-top: 25px;
		.zoompro-border {
			border: 1px solid $border-color;
		}
		.sp-img_slider {
			padding: 0 30px;
			margin-top: 30px;
			.slick-list {
				margin: 0 -15px;
				.slick-slide {
					border: 1px solid $border-color;
					margin: 0 15px;
					img {
						width: 100%;
					}
				}
			}
		}
		.sp-content {
			@include breakpoint (max-medium) {
				padding-top: 30px;
			}
			.sp-heading {
				> h2 {
					margin-bottom: 0;
					padding-bottom: 25px;
					font-size: 28px;
					font-weight: $body-font_weight;
					@include breakpoint (max-x_small) {
						font-size: 18px;
					}
				}
			}
			.rating-box {
				padding: 0 0 20px;
				display: flex;
				@include breakpoint (xx-small) {
					display: block;
				}
				> ul {
					> li {
						display: inline-block;
						> i {
							color: $primary-color;
						}
					}
				}
				.rating-info {
					padding-left: 15px;
				}
			}
			.price-box {
				padding-bottom: 15px;
				@include old-price;
				.old-price {
					display: inline-block;
					font-size: 19px;
				}
				@include new-price;
				.new-price {
					font-size: 31px;
					color: inherit;
					font-weight: 600;
				}
			}
			.product-desc {
				> p {
					margin-bottom: 0;
				}
			}
			.munoz-countdown_area {
				display: flex;
				justify-content: flex-start;
				padding-top: 5px;
				margin-bottom: -5px;
				.munoz-countdown {
					width: 100%;
					@include breakpoint(xx-small) {
						width: 85%;
					}
					.count {
						@include breakpoint (max-medium){
							width: 15%;
						}
						@include breakpoint (max-x_small){
							width: 20%;
						}
						@include breakpoint (xx-small){
							width: 25%;
						}
					}
				}
			}
			.product-size_box {
				display: flex;
				align-items: center;
				padding-top: 25px;
				> span {
					padding-right: 15px;
				}
				.myniceselect {
					&.nice-select {
						> span {
							height: 40px;
							line-height: 40px;
							padding: 0 30px;
						}
					}
				}
			}
			.color-list_area {
				margin-top: 25px;
			}
			.in-stock {
				padding-top: 25px;
				> i {
					padding-right: 5px;
					color: $bg-kelly-green_color;
				}
			}
			.quantity {
				padding-top: 25px;
				display: flex;
				align-items: center;
				.cart-plus-minus {
					display: inline-block;
					margin-left: 15px;
				}
				.additional-btn_area {
					padding-left: 15px;
					.additional_btn {
						border: 2px solid $primary-color;
						border-radius: 25px;
						font-weight: $heading-font_weight;
						width: 170px;
						height: 45px;
						line-height: 43px;
						text-align: center;
						display: block;
						font-size: 16px;
						text-transform: uppercase;
						@include breakpoint (xx-small){
							width: 110px;
							font-size: 14px;
							text-transform: capitalize;
						}
						&:hover {
							background-color: $primary-color;
							color: $bg-white_color !important;
						}
					}
				}
			}
			.qty-btn_area {
				padding: 30px 0;
				border-bottom: 1px solid $border-color;
				> ul {
					li {
						display: inline-block;
						padding-left: 5px;
						@include breakpoint (xx-small) {
							width: 100%;
							display: block;
						}
						&:first-child {
							padding-left: 0;
							@include breakpoint (xx-small) {
								padding-bottom: 30px;
							}
						}
						> a {
							&.qty-btn {
								display: inline-flex;
								align-items: center;
								justify-content: center;
								@extend %basetransition;
							}
							> i {
								border: 1px solid $primary-color;
								color: $primary-color;
								display: block;
								margin-right: 10px;
								font-size: 16px;
								width: 35px;
								height: 35px;
								line-height: 36px;
								text-align: center;
								border-radius: 100%;
								@extend %basetransition;
							}
							&:hover {
								> i {
									background-color: $primary-color;
									color: $bg-white_color !important;
								}
							}
						}
					}
				}
			}
			.category-list_area {
				display: flex;
				align-items: baseline;
				padding-top: 25px;
				@include breakpoint(xx-small) {
					display: block;
				}
				> h6 {
					margin-bottom: 0;
					@include breakpoint(xx-small) {
						padding-bottom: 10px;
					}
				}
				ul {
					padding-left: 5px;
					> li {
						display: inline-block;
						> a {
							color: $dim-gray_color;
						}
					}
				}
				&.tag-list_area {
					padding-top: 20px;
				}
			}
			.munoz-social_link {
				border-top: 1px solid $border-color;
				padding-top: 30px;
				margin-top: 25px;
				display: flex;
				align-items: center;
				@include breakpoint (xx-small) {
					display: block;
				}
				> h6 {
					font-size: 14px;
					margin-bottom: 0;
					padding-right: 15px;
					@include breakpoint (xx-small) {
						padding-right: 0;
						padding-bottom: 25px;
					}
				}
				ul {
					> li {
						> a {
							border: 1px solid $border-color;
						}
					}
				}
			}
		}
	}
}

/* ---Single Product Group Page--- */
.sp-group_area {
	.sp-nav {
		.sp-content {
			.choose-group-product {
				padding-top: 30px;
				.cart-table {
					table {
						margin-bottom: 0;
						tbody {
							tr {
								td {
									vertical-align: middle;
									text-align: center;
								}
							}
						}
					}
				}
			}
			.qty-btn_area{
				padding: 25px 0 30px;
			}
		}
	}
}

/* ---Single Product Variable Area--- */
.sp-variable_area {
	.sp-img_area {
		.sp-img_slider-2 {
			.single-slide {
				> a {
					pointer-events: none;
					> i {
						pointer-events: visible;
					}
				}
			}
		}
	}
}

/* ---Single Produc Affiliate Page--- */
.sp-affiliate_area {
	.sp-content {
		.qty-btn_area {
			display: inline-block;
			padding: 25px 0 0 !important;
			border-bottom: 0;
			a {
				&.qty-buy_btn {
					background-color: $heading-color;
					color: $bg-white_color;
					padding: 15px 30px;
					display: block;
					&:hover {
						background-color: $primary-color;
						color: $bg-white_color !important;
					}
				}
			}
		}
	}
}

/* ---Single Product Slider Area--- */
.sp-slider_area {
	padding-top: 60px;
	.sp-nav {
		padding: 0;
		.single-product{
			@extend %basetransition;
		}
		.sp-content {
			padding-top: 25px !important;
			float: unset;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			.rating-box {
				justify-content: center;
			}
			.product-size_box {
				justify-content: center;
			}
			.quantity {
				justify-content: center;
			}
			.munoz-tag-line_area {
				justify-content: center;
				@include breakpoint (max-x_small) {
					margin: 0 -30px;
				}
			}
			.munoz-social_link {
				justify-content: center;
				> ul {
					> li {
						> a {
							@include breakpoint (xx-small) {
								width: 35px;
							}
						}
					}
				}
			}
		}
	}
}

/* ---Single Product Gallery--- */
.sp-gallery_area {
	.sp-nav {
		padding-top: 30px;
		.sp-gallery {
			.lg-image {
				@extend %basetransition;
				padding: 5px;
				@include breakpoint (max-medium) {
					margin-bottom: 30px;
				}
			}
			> .row {
				&:first-child {
					margin-bottom: 30px;
					@include breakpoint (max-medium) {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

/* ---Single Product Tab Style--- */
.sp-tab-style_left {
	.sp-img_area {
		display: flex;
		flex-direction: row-reverse;
		.sp-large_img {
			flex-basis: 80%;
			width: 80%;
		}
		.sp-img_slider-3 {
			flex-basis: 20%;
			width: 20%;
		}
	}
	&.sp-tab-style_right {
		.sp-img_area {
			flex-direction: unset !important;
		}
	}
}

/* ---Single Product Sticky--- */
.sp-sticky_area {
	.sp-sticky_gallery {
		padding-left: 0 !important;
		> .row {
			&:first-child {
				margin-bottom: 0 !important;
			}
			[class*="col-"] {
				.lg-image {
					margin-bottom: 30px;
				}
			}
			&:last-child {
				[class*="col-"] {
					&:last-child {
						.lg-image {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}
/* ---Single Product Tab Area--- */
.sp-tab_area {
	padding-top: 55px;
	border-bottom: 0;
	.product-tab {
		.product-menu {
			@include breakpoint (max-x_small) {
				display: block;
				text-align: center;
			}
			> li {
				@include breakpoint (max-x_small) {
					margin-bottom: 15px;
				}
				> a {
					font-weight: $body-font_weight;
					color: $bg-nobel_color;
					padding: 0 30px 10px;
					display: block;
					font-size: 22px;
					@include breakpoint (max-small) {
						padding: 0 20px 10px;
					}
					@include breakpoint (max-x_small) {
						padding: 0 0 10px;
					}
					> span{
						position: relative;
						color: $dim-gray_color;
						&:before {
							content: "";
							background: $primary-color;
							height: 1px;
							width: 100%;
							position: absolute;
							bottom: -10px;
							left: 0;
							transform: scale(0);
							@extend %basetransition;
						}
					}
					&.active {
						color: $primary-color;
						> span{
							color: $primary-color;
							&:before {
								transform: scale(1);
							}
						}
					}
				}
				&:hover {
					> a {
						> span{
							color: $primary-color;
							&:before {
								transform: scale(1);
							}
						}
					}
				}
				&:first-child{
					> a{
						padding-left: 0;
					}
				}
			}
		}
	}
	.tab-content {
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		padding: 25px 0;
		.product-description {
			> p {
				margin-bottom: 0;
				padding-top: 0px;
				&.additional-desc {
					padding-top: 15px;
				}
			}
		}
		.additional-information-inner_stuff {
			margin-bottom: 0;
		}
		.form-horizontal {
			.table-striped {
				margin-bottom: 25px;
				> tbody {
					tr {
						background-color: $bg-white_color;
						&:nth-child(2) {
							td {
								> p {
									margin-bottom: 0;
									padding-bottom: 20px;
								}
								.rating-box {
									> ul {
										> li {
											display: inline-block;
											> i {
												color: $primary-color;
											}
										}
									}
								}
							}
						}
						td {
							padding: 20px;
						}
					}
				}
			}
			h2 {
				margin-bottom: 0;
				padding-bottom: 15px;
			}
			.form-group {
				margin-bottom: 0;
				padding-bottom: 15px;
				&.second-child {
					padding-bottom: 10px;
				}
				&.last-child {
					padding-bottom: 0;
				}
				.review-input,
				.review-textarea {
					border: 1px solid $border-color;
					width: 100%;
					height: 30px;
					color: $input-placeholder_color;
					&.review-textarea {
						height: 120px;
					}
				}
				label {
					margin-bottom: 0;
					padding-bottom: 18px;
				}
				.help-block {
					padding-top: 18px;
				}
				.munoz-btn-ps_right {
					display: flex;
					justify-content: flex-end;
					@include breakpoint (xx-small) {
						justify-content: flex-start;
						padding-top: 20px;
					}
				}
				.your-opinion {
					span {
						.br-wrapper {
							.br-widget {
								a {
									font-size: 16px;
									&.br-active {
										&:after {
											color: $primary-color;
										}
									}
									&.br-selected {
										&:after {
											color: $primary-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* ---Single Product Slider Area--- */
.sp-product-slider_area {
	padding: 40px 0 60px;
}
