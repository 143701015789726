//--- All Variables - Related Stuff Included In This Area ---↓

//--- Typography Section Variables ---↓

// Torress Default Color
$primary-color: #09823F;
$primary-color-2: #e23129;
$body-text_color: #333333;
$heading-color: #333333;
$border-color: #ebebeb;
$border-color-2: #d2d2d2;
$header-bg_color: #232f3e;
$hm-bg_color: #303d4e;
$header-border_color: #394452;
$header-text_color: #282828;
$footer-bg_color: #f8f8f9;

// Additional Color Variants
$bg-licorice_color: #2a3747;
$dim-gray_color: #666666;
$bg-white_color: #ffffff;
$bg-alizarin_color: #ea3a3c;
$white-smoke_color: #f4f4f4;
$bg-snow_color: #f9f9f9;
$bg-whisper_color: #ebebeb;
$bg-silver_color: #bbbbbb;
$bg-eclipse_color: #393939;
$bg-nero_color: #282828;
$bg-yellow_color: #fff000;
$bg-cinnabar_color: #d64444;
$bg-wedgewood_color: #516a7e;
$bg-cello_color: #344b5d;
$bg-red_color: #e01212;
$fire-engine-red_color: #cd131c;
$bg-orange-red_color: #ff4000;
$bg-silver_color: #bababa;
$bg-dark-gray_color: #abaaaa;
$input-placeholder_color: #888888;
$very-light-grey: #cccccc;
$grey-color: #7b7b7b;
$burnt-orange_color: #ff832b;
$brown_color: #a6311f;
$raw-umber_color: #824900;
$bg-black_color: #000000;
$bg-supernova_color: #fdb828;
$bg-mahogany_color: #d8373e;
$bg-nobel_color: #999999;
$bg-silver_color: #c0c0c0;
$bg-selago_color: #ebe9eb;
$bg-cloud-burst_color: #363f4d;
$bg-kelly-green_color: #43ae2e;
$bg-zambezi_color: #606060;
$bg-blue_color: #00b3e6;
$bg-amaranth_color: #e23e57;

// RGB Color Variants
$border-rgba_color: rgba(255, 255, 255, 0.1);
$border-rgba_color-2: rgba(255, 255, 255, 0.2);

// Font Size
$body-font_size: 16px;
$heading-font_size: 24px;
$munoz-border_radius: 4px;

// Font Line Height
$line-height: 24px;
$heading-line_height: 1;

// Font Weight
$body-font_weight: 400;
$body-font_weight-2: 700;
$heading-font_weight: 700;
$heading-font_weight-2: 400;

// Font Family
$body-font: 'Lato', sans-serif;
$heading-font: 'Lato', sans-serif;

//----Social Link Color Variables ----↓
$facebook-color: #3b579d;
$twitter-color: #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color: #0097bd;
$pinterest-color: #cb2027;
$instagram-color: #833ab4;
$skype-color: #00aff0;
$share-color: #fe6d4c;
$youtube-color: #d72525;

// Munoz's Template Color Variation ----↓
$color-1: $primary-color;
$color-2: $primary-color-2;
$colorList: $color-1, $color-2;
