/*----------------------------------------*/
/*  14 - Components - Banner
/*----------------------------------------*/
.munoz-banner_area {
	padding-top: 30px;
	.banner-item {
		> a {
			> img {
				width: 100%;
			}
		}
	}
	[class*="col-"]{
		&:not(:last-child){
			.banner-item{
				@include breakpoint (max-x_small){
					margin-bottom: 30px;
				}
			}
		}
	}
}

/* ---Defoody Banner Area Two--- */
.munoz-banner_area{
	padding-top: 60px;
}