/*----------------------------------------*/
/*  26 - Section - Footer
/*----------------------------------------*/
.munoz-footer_area {
	background-color: $footer-bg_color;
	margin-top: 60px;
	.footer-top_area {
		padding: 60px 0 55px;
		border-bottom: 1px solid $border-color;
		.newsletter-info {
			@include breakpoint(max-medium) {
				text-align: center;
				padding-bottom: 20px;
			}
			> h3 {
				&.heading {
					margin-bottom: 0;
					font-size: 18px;
					text-transform: uppercase;
					font-weight: 600;
					@include breakpoint (xx-small) {
						line-height: 26px;
					}
				}
			}
			> p {
				&.short-desc {
					margin-bottom: 0;
					padding-top: 10px;
					max-width: 95%;
					@include breakpoint (max-medium){
						margin: 0 auto;
						max-width: 75%;
					}
					@include breakpoint (xx-small){
						max-width: 100%;
					}
				}
			}
		}
	}
	.footer-middle_area {
		padding: 55px 0 50px;
		border-bottom: 1px solid $border-color;
		[class*="col-"] {
			&:nth-child(-n+2) {
				@include breakpoint (max-small) {
					padding-bottom: 50px;
				}
			}
		}
		.footer-widgets_title {
			> h4 {
				font-weight: $body-font_weight;
				padding-bottom: 20px;
				font-size: 22px;
				margin-bottom: 0;
				@include breakpoint (normal) {
					font-size: 19px;
				}
				@include breakpoint (xx-small) {
					font-size: 19px;
				}
			}
			&.widgets-info_title {
				> h4 {
					padding-bottom: 25px;
				}
			}
		}
		.widget-short_desc {
			> p {
				margin-bottom: 0;
				padding-bottom: 25px;
			}
		}
		.footer-widgets {
			> ul {
				> li {
					padding-bottom: 10px;
					&:last-child {
						padding-bottom: 0;
					}
					> a {
						@include breakpoint(normal) {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							display: block;
						}
					}
				}
			}
		}
	}
	/* ---Footer Upper Bottom--- */
	.footer-upper_bottom {
		padding: 35px 0;
		border-bottom: 1px solid $border-color;
		.footer-tag_links {
			display: flex;
			align-items: baseline;
			> h3{
				&.footer-tag_title{
					font-size: 16px;
					text-transform: uppercase;
					margin-bottom: 0;
				}
			}
			> ul {
				> li {
					display: inline-block;
					&:last-child {
						> a {
							border-right: none;
						}
					}
				}
				a {
					border-right: 1px solid $body-text_color;
					display: inline-block;
					font-size: 15px;
					padding: 0 5px;
					line-height: 1;
					@extend %basetransition;
				}
			}
		}
	}

	/* ---Footer Bottom--- */
	.footer-bottom_area {
		padding: 20px 0;
		.copyright {
			font-size: 14px;
			line-height: 30px;
			text-align: center;
			> span {
				> a {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
