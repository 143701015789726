/*----------------------------------------*/
/*  25 - Section - Header
/*----------------------------------------*/

/* ---Header Main Area--- */
.header-main_area {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	@include breakpoint (max-normal){
		position: relative;
	}
	> .container {
		position: relative;
	}
	/* ---Header Logo--- */
	.header-logo{
		padding: 30px 0;
	}
	/* ---Header Main Menu--- */
	.main-menu_area {
		display: flex;
		justify-content: center;
		> nav {
			> ul {
				> li {
					display: inline-block;
					padding-right: 40px;
					@include dropdown;
					> ul {
						> li {
							position: relative;
							// Begin Header Middle Sub Dropdown Area
							> ul {
								&.hm-sub_dropdown {
									top: 30px;
									left: 100%;
									opacity: 0 !important;
									visibility: hidden !important;
									@include breakpoint (normal) {
										left: auto;
										right: 100%;
									}
								}
							}
							> a {
								display: block;
								> i {
									position: absolute;
									top: 14px;
									right: 25px;
									display: block;
									font-size: 12px;
									@extend %basetransition;
								}
							}
							&:hover {
								> .hm-sub_dropdown {
									top: 0;
									opacity: 1 !important;
									visibility: visible !important;
								}
							}
							// Header Middle Sub Dropdown Area End Here
						}
					}
					&.megamenu-holder {
						position: static;
						@include megamenu;
						> ul {
							> li {
								width: 33.33%;
								> span {
									&.megamenu-title {
										font-size: 18px;
										font-weight: 700;
										text-transform: uppercase;
										display: block;
										padding-bottom: 15px;
										@include breakpoint (normal) {
											font-size: 15px;
										}
									}
								}
								> ul {
									> li {
										> a {
											display: block;
											line-height: 35px;
											font-size: 16px;
										}
									}
								}
							}
						}
					}
					> a {
						display: block;
						padding: 48px 0;
						color: $heading-color;
						font-weight: $heading-font_weight;
						text-transform: uppercase;
						position: relative;
						&:before {
							content: "";
							border-left-width: 2px;
							border-left-style: solid;
							border-left-color: inherit;
							position: absolute;
							top: 50%;
							left: -20px;
							transform: translateY(-50%);
							height: 13px;
							opacity: 0;
							visibility: hidden;
							@extend %basetransition;
						}
						&:after {
							content: "";
							border-left-width: 2px;
							border-left-style: solid;
							border-left-color: inherit;
							position: absolute;
							top: 50%;
							right: -20px;
							transform: translateY(-50%);
							height: 13px;
							opacity: 0;
							visibility: hidden;
							@extend %basetransition;
						}
					}
					&:hover {
						> a {
							&:before {
								opacity: 1;
								visibility: visible;
								left: -10px;
							}
							&:after {
								opacity: 1;
								visibility: visible;
								right: -10px;
							}
						}
					}
				}
			}
		}
	}
	/* ---Header Right Area--- */
	.header-right_area{
		display: flex;
		justify-content: flex-end;
		padding: 50px 0 0;
		> ul{
			> li{
				display: inline-block;
				padding-left: 30px;
				@include breakpoint (normal){
					padding-left: 20px;
				}
				> a{
					font-size: 25px;
				}
				&:last-child{
					padding-left: 0;
				}
			}
		}
	}
	&.header-sticky {
		display: none;
	}
}

/* ---Header Main Area Two--- */
.header-main_area-2{
	background-color: rgba(0, 0, 0, 0.3);
	@include breakpoint (max-normal){
		background-color: rgba(0, 0, 0, 0.8);
	}
	.main-menu_area{
		> nav{
			> ul{
				> li{
					> a{
						color: $bg-white_color;
					}
				}
			}
		}
	}
	.header-right_area{
		> ul{
			> li{
				> a{
					color: $bg-white_color;
				}
			}
		}
	}
}

/* ---Header Main Area Three--- */
.header-main_area-3{
	position: relative;
}

/* ---Mobile Menu Area--- */
.mobile-menu_area {
	display: flex;
	justify-content: flex-end;
	@include breakpoint (max-x_small) {
		justify-content: center;
		padding: 30px 0;
	}
	ul {
		> li {
			display: inline-block;
			&.minicart-area {
				width: 50px;
				height: 50px;
				line-height: 50px;
				border-radius: 25%;
				text-align: center;
				position: relative;
				margin-right: 15px;
				> a {
					display: block;
					> i {
						font-size: 20px;
						color: $bg-white_color;
						vertical-align: baseline;
					}
					> span {
						&.item-count {
							background: $bg-supernova_color;
							color: $bg-white_color;
							position: absolute;
							top: -15px;
							right: -5px;
							width: 25px;
							height: 25px;
							line-height: 23px;
							border-radius: 50%;
							font-size: 14px;
						}
					}
				}
			}
			> a {
				> i {
					font-size: 36px;
					vertical-align: middle;
					color: $bg-white_color;
				}
			}
		}
	}
}

/* ---Header Sticky--- */
.header-main_area {
	&.header-sticky.sticky {
		background-color: $bg-white_color;
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		transition: all 300ms ease-in 0s;
		z-index: 1049;
		@include breakpoint(max-x_small) {
			position: static;
			transition: none;
		}
		&.sticky {
			animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
		}
		&.header-main_area {
			display: none;
		}
		&.header-sticky {
			display: block;
			@include breakpoint(max-medium) {
				padding: 20px 0;
			}
			.header-logo {
				padding: 15px 0 0;
				@include breakpoint (max-medium){
					padding: 0;
				}
				> a {
					> img {
						width: 100px;
					}
				}
			}
		}
		.main-menu_area {
			justify-content: flex-start;
			> nav{
				> ul{
					> li{
						> a{
							padding: 28px 0;
						}
					}
				}
			}
		}
		.header-right_area{
			padding: 30px 0 0;
			@include breakpoint (max-medium){
				padding: 10px 0 0;
			}
			> ul{
				> li{
					&:nth-child(n+3){
						padding-right: 0;
					}
				}
			}
		}
	}
}
